
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinAggregation from '@/mixins/mixinAggregation';
import AggregationMain from "@/components/aggregation/Main.vue";

@Component({
  components: {
    AggregationMain,
  }
})
export default class Aggregation extends Mixins(mixinAggregation) {

}
